<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row style="background-color: #f2f2f2">
      <v-col
        cols="12"
        md="5"
      >
        <b>Hotetec: {{ hotel.name.valueCsv }}</b>
      </v-col>
      <v-col
        v-if="showTab && pos === indexTab"
        cols="12"
        md="3"
      >
        <!--<v-select
          v-if="hotel.name.action !== 'add'"
          v-model="hotel.name.id"
          :items="hotelsFree"
          :label="$t('lbl.hotel')"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
          clearable
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.hotel') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>-->
        <v-autocomplete
          v-if="hotel.name.action !== 'add'"
          v-model="hotel.name.id"
          :items="hotelsList"
          :search-input.sync="hotelSearch"
          hide-details="auto"
          hide-selected
          :label="$t('lbl.hotel')"
          outlined
          dense
          item-text="name"
          item-value="id"
          class="pt-2"
          clearable
          single-line
          @change="setHotel"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ $t('lbl.hotel') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <span v-text="item.name"></span>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>{{ item.nameComodin }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
        <v-text-field
          v-else
          v-model="hotel.name.name"
          :label="$t('lbl.hotel')"
          outlined
          dense
          hide-details
          clearable
          @click:clear="hotel.name.action = ''"
        >
        </v-text-field>
      </v-col>
      <v-col
        v-if="showTab && pos === indexTab"
        cols="12"
        md="3"
      >
        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              :disabled="hotel ? ((hotel.name.id || hotel.name.action === 'add') ? true : false) : false"
              v-on="on"
              @click="addModal"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>

        <v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              class="ml-5"
              :disabled="hotel ? !hotel.name.id : false"
              v-on="on"
              @click="showRemplace"
            >
              <v-icon>{{ icons.mdiFileReplaceOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.remplace') }}</span>
        </v-tooltip>

        <!--<v-tooltip
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              class="ml-5"
              :disabled="!hotel.name.id"
              v-on="on"
              @click="showAsociate"
            >
              <v-icon>{{ icons.mdiLinkVariantPlus }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.asociate') }}</span>
        </v-tooltip>-->
        <v-tooltip
          v-if="hotelsChanges.length > 1"
          top
          color="error"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="error"
              outlined
              class="ml-5"
              v-on="on"
              @click="showDelete()"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
        <v-tooltip
          v-if="hotelsChanges.length > 1"
          top
          color="primary"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              x-small
              v-bind="attrs"
              color="primary"
              outlined
              class="ml-5"
              :disabled="verifyHotel"
              v-on="on"
              @click="addSave"
            >
              <v-icon>{{ icons.mdiUpdate }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.save') }}</span>
        </v-tooltip>
      </v-col>

      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="1"
      >
        <v-btn
          icon
          @click="showItem()"
        >
          <v-icon>
            {{ showTab && pos === indexTab ? icons.mdiMenuDown : icons.mdiMenuRight }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="showTab && pos === indexTab">
      <v-col
        cols="12"
        md="12"
      >
        <v-row>
          <v-col
            v-if="hotel.name.id || hotel.name.action === 'add'"
            cols="12"
            md="12"
          >
            <ImportFilesRoom
              v-for="(room, indR) in hotel.rooms"
              :key="indR"
              :lote="lote"
              :pos="indR"
              :hotel="hotel"
              :room="room"
              :rooms="allRooms"
              :hotels="listHotel"
            />
          </v-col>
          <v-col
            v-if="hotel.name.id"
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="11"
                offset-md="1"
              >
                <v-divider
                  class="mb-1 mt-1"
                ></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <ImportFilesPlan
              v-for="(plan, indR) in hotel.plans"
              :key="indR"
              :lote="lote"
              :pos="indR"
              :hotel="hotel"
              :plan="plan"
              :plans="allPlans"
            />
          </v-col>

          <v-col
            v-if="hotel.name.id"
            cols="12"
            md="12"
          >
            <v-row>
              <v-col
                cols="12"
                md="11"
                offset-md="1"
              >
                <v-divider
                  class="mb-1 mt-1"
                ></v-divider>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="12"
          >
            <ImportFilesTarifas
              v-for="(tarifa, indR) in hotel.tarifas"
              :key="indR"
              :lote="lote"
              :pos="indR"
              :hotel="hotel"
              :tarifa="tarifa"
              :categories-tarifarios="allCategoriesTarifarios"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <v-divider
          class="mb-1 mt-1"
        ></v-divider>
      </v-col>
    </v-row>

    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addChange', { n: 'el hotel' }) }}
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="hotel.name.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL REMPLACE-->
    <v-dialog
      v-model="isAddRemplace"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.remplace')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              {{ $t('msg.addRemplace', { m: hotel.name.name, n: listHotel.filter(e => e.id === hotel.name.id).length > 0 ? listHotel.filter(e => e.id === hotel.name.id)[0].name : '' }) }}
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="hotel.name.name"
                :label="$t('lbl.hotel')"
                outlined
                dense
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddRemplace = !isAddRemplace"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddRemplace()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--MODAL ASOCIATE--
    <v-dialog
      v-model="isAddAsociate"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.asociate')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addAsociate', { m: hotel.name.name, n: listHotel.filter(e => e.id === hotel.name.id).length > 0
              ? listHotel.filter(e => e.id === hotel.name.id)[0].name
              : ''
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddAsociate = !isAddAsociate"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddAsociate()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->
    <!--MODAL DELETE VALUE-->
    <v-dialog
      v-model="isAddDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.addDelete', { n: hotel.name.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddDelete = !isAddDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ADD SAVE -->
    <v-dialog
      v-model="isAddSave"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.save')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.addSave', { n: `el hotel ${hotel.name.name}` }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddSave = !isAddSave"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddSave()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiLinkVariantPlus,
  mdiFileReplaceOutline,
} from '@mdi/js'
import ImportFilesRoom from './ImportFilesRoom.vue'
import ImportFilesPlan from './ImportFilesPlan.vue'
import ImportFilesTarifas from './ImportFilesTarifas.vue'

export default {
  components: {
    ImportFilesRoom,
    ImportFilesPlan,
    ImportFilesTarifas,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    lote: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    listHotel: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotelsPact: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotelsPactUses: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allPlans: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allCategoriesTarifarios: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      showRow: false,
      isAddChange: false,
      addChange: false,
      isAddDelete: false,
      isAddRemplace: false,
      isAddAsociate: false,
      isAddSave: false,
      modelHotel: {
        id: null,
      },
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiLinkVariantPlus,
        mdiFileReplaceOutline,
      },
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
      hotelsContrate: [],
      hotelsList: [],
      hotelSearch: null,
    }
  },
  computed: {
    ...mapState({
      indexTab: state => state.app.indexTab,
      showTab: state => state.app.showTab,
      hotelsChanges: state => state.app.hotelsChanges,
    }),

    hotelsFree() {
      const result = []
      const hotelsContrate = []
      this.listHotel.forEach(element => {
        if (this.hotelsPact.includes(element.id)) {
          hotelsContrate.push(element)
        }
      })

      hotelsContrate.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.hotelsChanges.length; index++) {
            if (this.hotelsChanges[index].name.id === element.id && index !== this.pos) {
              esta = true
            }
          }

          // VERIFICAR QUE YA NO ESTE EN EL CONTRATO
          if (this.hotelsPactUses.includes(element.id)) {
            esta = true
          }

          if (!esta) {
            result.push(element)
          }
        }
      })

      return result
    },
    verifyHotel() {
      let result = false
      if (this.hotel) {
        if (!this.hotel.name.id) {
          if (this.hotel.name.action !== 'add') {
            result = true
          }
        }
        this.hotel.rooms.forEach(element => {
          if (!element.room.id) {
            if (element.room.action !== 'add') {
              result = true
            }
          }
        })
        this.hotel.plans.forEach(element => {
          if (!element.id) {
            result = true
          }
        })
      }

      return result
    },
  },
  watch: {
    hotelSearch(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterHotel(val.toLowerCase())
      } else {
        this.hotelsList = this.hotelsFree
      }
    },
  },

  created() {
    this.hotelsList = this.hotelsFree
  },
  methods: {
    ...mapMutations([
      'setShowTab',
      'setIndexTab',
      'updateHotelsChange',
      'setHotelsChange',
    ]),
    filterHotel() {
      this.hotelsList = this.hotelsFree.filter(e => e.name.toLowerCase())
    },
    showItem() {
      this.hotelsList = this.hotelsFree
      if (!this.showTab) {
        this.setShowTab(true)
        this.setIndexTab(this.pos)
      } else if (this.showTab && this.pos === this.indexTab) {
        this.setShowTab(false)
        this.setIndexTab(-1)
      } else if (this.showTab && this.pos !== this.indexTab) {
        this.setIndexTab(this.pos)
      }
    },
    setHotel() {
      // this.hotelsList = this.hotelsFree
      this.setHotelsChange({ pos: this.pos, item: this.hotel })
    },
    addModal() {
      this.isAddChange = true
    },
    confirmAddChange() {
      this.addChange = true
      const json = {
        hotel: {
          name: this.hotel.name.name,
          operador_id: null,
          valueCsv: this.hotel.name.valueCsv,
        },
      }

      this.axios
        .post(
          `lotes-contrate-hotetec/add-change/${this.lote.id}`,
          { type: 'hotel', changes: json },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('getLote')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.resetItem()
        })
    },
    showRemplace() {
      this.isAddRemplace = true
    },
    confirmAddRemplace() {
      this.addChange = true
      const json = {
        newValue: this.hotel.name.name,
        typeName: null,
        valueCsv: this.hotel.name.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.hotel.name.id,
        type: 'hotel',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-remplace/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // this.$toast.success(this.$t('msg.infoSuccess'))
          this.$emit('getLote')
        })
        .catch(error => console.log(error))
        .finally(() => {
          this.resetItem()
        })
    },
    showAsociate() {
      this.isAddAsociate = true
    },
    confirmAddAsociate() {
      this.addChange = true
      const json = {
        newValue: this.hotel.name.name,
        typeName: null,
        valueCsv: this.hotel.name.valueCsv,
        codeHot: this.hotel.codeHot,
        idValue: this.hotel.name.id,
        type: 'hotel',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-asociate/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('getLote')
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.resetItem()
        })
    },
    showDelete() {
      this.isAddDelete = true
    },
    confirmAddDelete() {
      this.addChange = true
      const json = {
        newValue: this.hotel.name.name,
        typeName: this.hotel.name.name,
        type: 'hotel',
      }

      this.axios
        .post(`lotes-contrate-hotetec/add-delete/${this.lote.id}`, json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('getLote')
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.resetItem()
        })
    },
    addSave() {
      this.isAddSave = true
    },
    confirmAddSave() {
      this.addChange = true
      const json = {
        item: this.hotel,
        id: this.lote.id,
        contrate_id: localStorage.getItem('hotels-contrate-id'),
      }

      this.axios
        .post('lotes-contrate-hotetec/process-item', json,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$emit('getLote')

          // this.$emit('setModify')
        })
        .catch(error => console.log(error))
        .finally(() => {
          // PARA Q PUEDA VERIFICAR DE NUEVO SI YA ESTA EN USO EL HOTEL
          /* setTimeout(() => {
            window.location.reload()
          }, 1000) */

          this.resetItem()
        })
    },
    resetItem() {
      this.addChange = false
      this.isAddChange = false
      this.isAddDelete = false
      this.isAddRemplace = false
      this.isAddAsociate = false
      this.isAddSave = false
    },
    getL() {
      this.$emit('getLote')
    },
  },
}
</script>
